import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

import { UserConfigsStoreFacade } from '../../../store/user-configs/user-configs-store.facade';

@Pipe({
  name: 'resiDate',
  standalone: true
})
export class ResiDatePipe implements PipeTransform {
  private datePipe = inject(DatePipe);
  private userFacade = inject(UserConfigsStoreFacade);

  public transform(value: Date | string | undefined): string | null {
    const countryCode = this.userFacade.userLocationSignal();

    // Data formatting based on requirements from https://jira.bbraun.com/browse/W39TOM-8840

    return countryCode === 'CH'
      ? this.datePipe.transform(value, 'dd.MM.yyyy')
      : this.datePipe.transform(value, 'dd/MM/yyyy');
  }
}
