import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i3 from '@angular/material/menu';
import { MatMenuModule } from '@angular/material/menu';
import * as i4 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
const _c0 = [[["", 8, "cx-menu-trigger"]], "*"];
const _c1 = [".cx-menu-trigger", "*"];
function CxMenuComponent_button_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 6, 2)(2, "mat-icon");
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const trigger_r1 = i0.ɵɵreference(1);
    const ctx_r1 = i0.ɵɵnextContext();
    const cxMenu_r3 = i0.ɵɵreference(6);
    i0.ɵɵclassProp("cx-menu-button-opened", trigger_r1.menuOpen);
    i0.ɵɵproperty("disabled", ctx_r1.disabled)("matMenuTriggerFor", cxMenu_r3);
    i0.ɵɵattribute("data-cy", ctx_r1.dataCy || "cx-menu");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r1.icon || "more_vert");
  }
}
const _c2 = ["*"];
class CxMenuComponent {
  static {
    this.ɵfac = function CxMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMenuComponent,
      selectors: [["cx-menu"]],
      inputs: {
        disabled: "disabled",
        icon: "icon",
        dataCy: "dataCy",
        matMenuCssClasses: "matMenuCssClasses"
      },
      standalone: false,
      ngContentSelectors: _c1,
      decls: 8,
      vars: 7,
      consts: [["containerForTrigger", "", "trigger", "matMenuTrigger"], ["cxMenu", "matMenu"], ["trigger", "matMenuTrigger"], [1, "d-inline-block", 3, "matMenuTriggerFor"], ["type", "button", "class", "cx-menu-button", "mat-icon-button", "", "disableRipple", "", 3, "disabled", "matMenuTriggerFor", "cx-menu-button-opened", 4, "ngIf"], ["xPosition", "before"], ["type", "button", "mat-icon-button", "", "disableRipple", "", 1, "cx-menu-button", 3, "disabled", "matMenuTriggerFor"]],
      template: function CxMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 3, 0);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, CxMenuComponent_button_4_Template, 4, 6, "button", 4);
          i0.ɵɵelementStart(5, "mat-menu", 5, 1);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const containerForTrigger_r4 = i0.ɵɵreference(1);
          const trigger_r5 = i0.ɵɵreference(2);
          const cxMenu_r3 = i0.ɵɵreference(6);
          i0.ɵɵclassProp("cx-menu-button-opened", trigger_r5.menuOpen);
          i0.ɵɵproperty("matMenuTriggerFor", cxMenu_r3);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", !containerForTrigger_r4.children.length);
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.matMenuCssClasses);
        }
      },
      dependencies: [i1.NgIf, i2.MatIcon, i3.MatMenu, i3.MatMenuTrigger, i4.MatIconButton],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMenuComponent, [{
    type: Component,
    args: [{
      selector: 'cx-menu',
      standalone: false,
      template: "<div\n  #containerForTrigger\n  class=\"d-inline-block\"\n  #trigger=\"matMenuTrigger\"\n  [matMenuTriggerFor]=\"cxMenu\"\n  [class.cx-menu-button-opened]=\"trigger.menuOpen\"\n>\n  <ng-content select=\".cx-menu-trigger\"></ng-content>\n</div>\n\n<button\n  *ngIf=\"!containerForTrigger.children.length\"\n  #trigger=\"matMenuTrigger\"\n  type=\"button\"\n  class=\"cx-menu-button\"\n  mat-icon-button\n  disableRipple\n  [disabled]=\"disabled\"\n  [attr.data-cy]=\"dataCy || 'cx-menu'\"\n  [matMenuTriggerFor]=\"cxMenu\"\n  [class.cx-menu-button-opened]=\"trigger.menuOpen\"\n>\n  <mat-icon>{{ icon || 'more_vert' }}</mat-icon>\n</button>\n\n<mat-menu xPosition=\"before\" #cxMenu=\"matMenu\" class=\"{{ matMenuCssClasses }}\">\n  <ng-content></ng-content>\n</mat-menu>\n"
    }]
  }], null, {
    disabled: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    dataCy: [{
      type: Input
    }],
    matMenuCssClasses: [{
      type: Input
    }]
  });
})();
class CxMenuItemComponent {
  static {
    this.ɵfac = function CxMenuItemComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxMenuItemComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxMenuItemComponent,
      selectors: [["cx-menu-item"]],
      standalone: false,
      ngContentSelectors: _c2,
      decls: 2,
      vars: 0,
      consts: [["mat-menu-item", ""]],
      template: function CxMenuItemComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      },
      dependencies: [i3.MatMenuItem],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMenuItemComponent, [{
    type: Component,
    args: [{
      selector: 'cx-menu-item',
      standalone: false,
      template: "<button mat-menu-item>\n  <ng-content></ng-content>\n</button>\n"
    }]
  }], null, null);
})();
const components = [CxMenuComponent, CxMenuItemComponent];
class CxMenuModule {
  static {
    this.ɵfac = function CxMenuModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxMenuModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxMenuModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxMenuModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatIconModule, MatMenuModule, MatButtonModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxMenuComponent, CxMenuItemComponent, CxMenuModule };
